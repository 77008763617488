import React, { useState, useEffect } from 'react';
import { EditorModeEnums, IStore } from '../../redux/store/IStore';
import UndoIcon from '@mui/icons-material/Undo';
import genStringFromWords from '../../shared/genStringFromWords';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setClickedTime, setManuallyUpdateEditorState } from '../../redux/features/app/app';

const generatePreviousTimes = (data) => {
  let prevTimes = '';

  for (let i = 0; i < data.startTime; i++) {
    prevTimes += `${i} `;
  }

  if (data.startTime % 1 > 0) {
    // Find the closest quarter-second to the current time, for more dynamic results
    const dec = Math.floor((data.startTime % 1) * 4.0) / 4.0;
    prevTimes += ` ${Math.floor(data.startTime) + dec}`;
  }

  return prevTimes;
};

// const generateStylesForConfidenceLvl = (
//   lvl: number,
//   border?: boolean
// ): React.CSSProperties | undefined => {
//   if (border) {
//     if (lvl <= 0.33) {
//       return {
//         borderTop: "2px solid rgb(215, 128, 27)",
//         borderBottom: "2px solid rgb(215, 128, 27)",
//       };
//     } else if (lvl <= 0.68) {
//       return {
//         borderTop: "2px solid rgba(215, 128, 27, 0.5)",
//         borderBottom: "2px solid rgba(215, 128, 27, 0.5)",
//       };
//     }
//   } else {
//     if (lvl <= 0.33) {
//       return {
//         color: "rgb(215, 128, 27)",
//       };
//     } else if (lvl <= 0.68) {
//       return {
//         color: "rgba(215, 128, 27, 0.5)",
//       };
//     }
//   }
//   return undefined;
// };

// const getColorFromConf = (conf: number): string => {
//   if (conf <= 0.33) {
//     return "rgb(215, 128, 27)";
//   } else if (conf <= 0.68) {
//     return "rgba(215, 128, 27, 0.5)";
//   }
//   return ''
// };

const initWordStyle: React.CSSProperties = {
  color: '#707070',
  fontWeight: 'normal',
};

const Word = (props: any) => {
  const [wordStyles, setWordStyles] = useState<React.CSSProperties>(initWordStyle);
  const [showBubble, setShowBubble] = useState(false);
  const dispatch = useAppDispatch();
  const confidence = useAppSelector(state => state.app.showConfidence);
  const currentTimeRedux = useAppSelector(state => state.app.currentTime);
  const editorMode = useAppSelector(state => state.app.editorMode);
  const isAudioPlaying = useAppSelector(state => state.app.isAudioPlaying);
  const wordEntitiyKey = props.entityKey;
  const wordData = wordEntitiyKey ? props.contentState.getEntity(wordEntitiyKey).getData() : {};

  const wordRef = React.useRef<HTMLSpanElement>(null);
  // console.log('Word rerender', wordData);
  useEffect(() => {
    if (editorMode !== EditorModeEnums.PLAYING_MODE) return;
    const word = wordRef.current;
    if (!word) return;
    // const generateStylesForCurrentTime = (start: number, end: number) => {
    if (currentTimeRedux < wordData.startTime) {
      // setWordStyles({
      //   ...wordStyles,
      //   color: "#CCCCCC",
      //   fontWeight: "normal",
      // });
      if (word.classList.contains('after')) return;
      word.classList.add('after');
      word.classList.remove('active');
      word.classList.remove('before');
    } else if (currentTimeRedux >= wordData.startTime && currentTimeRedux < wordData.endTime) {
      if (word.classList.contains('active')) return;
      // setActiveWordId(wordData.startTime.toString() + wordData.endTime.toString());

      isAudioPlaying &&
        word.scrollIntoView({
          block: 'center',
          inline: 'center',
        });
      //setWordStyles({ color: "#333333", fontWeight: "bold" });
      word.classList.remove('after');
      word.classList.add('active');
      word.classList.remove('before');
    } else if (!word.classList.contains('before')) {
      // setWordStyles({
      //   ...wordStyles,
      //   color: "#707070",
      //   fontWeight: "normal",
      // });
      if (word.classList.contains('before')) return;
      word.classList.remove('after');
      word.classList.remove('active');
      word.classList.add('before');
    }
    // };

    // generateStylesForCurrentTime(wordData.startTime, wordData.endTime);
  }, [currentTimeRedux, wordData.startTime, wordData.endTime, editorMode]);

  // useEffect(() => {
  //   if (!entitiesKeysToMerge || entitiesKeysToMerge.length === 0) return;
  //   if (entitiesKeysToMerge.includes(props.entityKey)) {
  //     wordRef.current?.classList.add("edited_word");
  //   }
  // }, [entitiesKeysToMerge]);

  useEffect(() => {
    if (editorMode === EditorModeEnums.EDIT_MODE) {
      const word = wordRef.current;
      word?.classList.remove('after');
      word?.classList.remove('active');
      word?.classList.remove('before');
      setWordStyles({ ...wordStyles, ...initWordStyle });
      return;
    }
  }, [editorMode]);

  useEffect(() => {
    if (wordData.isOverlapping) {
      const word = wordRef.current;
      word?.classList.add('overlapping');
    }
  }, [])

  useEffect(() => {
    const initWord = wordData.text;
    if (typeof initWord !== 'string') return;
    const newWord = props.children[0].props.text;

    if (props.start === 0 && initWord.trim().toLowerCase() === newWord.trim().toLowerCase()) {
      return;
    } else if (initWord !== undefined && initWord !== null && initWord.trim() !== newWord.trim()) {
      /* setWordStyles({
        ...wordStyles,
        backgroundColor: "rgba(5,136,248,.18)", color: '#2b8dff',
      }); */
      if (
        wordRef.current?.classList.contains('conf_low') ||
        wordRef.current?.classList.contains('conf_med')
      ) {
        wordRef.current.classList.remove('conf_med', 'conf_low');
      }
      wordRef.current?.classList.add('edited_word');
    }
    if (initWord.trim() === newWord.trim()) {
      /* setWordStyles({
        ...wordStyles,
        backgroundColor: "transparent",
      }); */

      wordRef.current?.classList.remove('edited_word');
    }
  }, [props.children[0].props]);

  useEffect(() => {
    const newWord = props.children[0].props.text;
    const toCompare = wordData.updatedText ? wordData.updatedText : wordData.text;

    if (newWord !== toCompare) {
      props.contentState.mergeEntityData(props.entityKey, { updatedText: newWord });
    }
  }, [props.children[0].props.text]);

  useEffect(() => {
    if (confidence) {
      const word = wordRef.current;
      if (word?.classList.contains('edited_word')) return;
      if (wordData.confidence <= 0.33) {
        word?.classList.add('conf_low');
      } else if (wordData.confidence <= 0.68) {
        word?.classList.add('conf_med');
      }
    }

    if (!confidence) {
      wordRef.current?.classList.remove('conf_low', 'conf_med');
    }
  }, [confidence]);

  const handleWordClick = React.useCallback(
    (e) => {
      if (editorMode !== EditorModeEnums.PLAYING_MODE) return;

      dispatch(setClickedTime((wordData.startTime + wordData.endTime) / 2));
    },
    [wordData.startTime, editorMode, dispatch, wordData.endTIme]
  );

  const handleMouseOver = React.useCallback(() => {
    if (wordData.metadata?.source === 'REPLACEMENT' && wordData.metadata?.original) {
      setShowBubble(true);
    }
  }, [wordData.metadata?.source, wordData.metadata?.original, setShowBubble]);

  const handleMouseLeave = React.useCallback(() => {
    if (wordData.metadata?.source === 'REPLACEMENT' && wordData.metadata?.original && showBubble) {
      setShowBubble(false);
    }
  }, [wordData.metadata?.source, wordData.metadata?.original, setShowBubble, showBubble]);

  const handleRedoReplacementClick = React.useCallback(
    (e: any) => {
      e.stopPropagation();

      let newText = genStringFromWords(wordData.metadata.original);
      if (!newText) return;
      dispatch(
        setManuallyUpdateEditorState({
          update: true,
          newText,
          rangeToReplace: [props.start, props.end],
          entityToMerge: props.entityKey,
          blockKey: props.blockKey,
        })
      );
    },
    [props, wordData, dispatch]
  );

  const finalText = React.useMemo(() => {
    if (!wordData?.metadata || !wordData.metadata.original) return '';
    const final = genStringFromWords(wordData.metadata.original);

    if (!final) return '';
    return final;
  }, [wordData?.metadata]);

  return (
    <span
      ref={wordRef}
      onClick={handleWordClick}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      data-replacement={
        wordData &&
        wordData.metadata &&
        wordData.metadata.source &&
        wordData.metadata.source === 'REPLACEMENT'
      }
      // data-prev-times={generatePreviousTimes(wordData)}
      data-test={`1`}
      data-start={wordData && wordData.startTime ? `${wordData.startTime}` : ''}
      data-end={wordData && wordData.endTime ? `${wordData.endTime}` : ''}
      data-confidence={wordData.confidence || '1'}
      //data-testing={wordData.}
      data-entity-key={props.entityKey || 'unknown'}
      className="draft_word"
      id={
        wordData && wordData.startTime && wordData.endTime
          ? (wordData.startTime + wordData.endTime).toFixed(4)
          : ''
      }
    >
      {props.children}

      {wordData.metadata && wordData.metadata.original && showBubble && (
        <div
          className="replacement_hover__bubble"
          onDoubleClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseMove={(e) => {
            if (window && window.getSelection) {
              if (window && window.getSelection()?.empty) {
                // Chrome
                window && window.getSelection()?.empty();
              } else if (window && window.getSelection()?.removeAllRanges) {
                // Firefox
                window && window.getSelection()?.removeAllRanges();
              }
            }
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <span>{finalText}</span>
          <UndoIcon fontSize="small" onClick={handleRedoReplacementClick} />
        </div>
      )}
    </span>
  );
};

export default React.memo(Word);

// Component to wrap all the new characters that come to our editor
export const NewWord = (props: any) => {
  return (
    <span
      className="new_word"
      style={props.decoratedText && props.decoratedText !== ' ' ? { color: '#2b8dff' } : {}}
    >
      {props.children}
    </span>
  );
};
