import axiosAuth from './axios';
import qs from 'qs';
import { config } from '../config';
import { IActiveConfiguration, IInverseTextNormalizator, IPunctuator, ITrueCaser } from '../redux/store/IStore';

const { keycloakUrl, keycloakClientId, keycloakAuthReam: keycloakRealm } = config;

export enum GrantTypesEnums {
  PASSWORD = 'password',
  REFRESH_TOKEN = 'refresh_token',
}

export interface keycloakTokensResponse {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  scope: string;
  session_state: string;
  token_type: string;
}

export const requestKeycloakTokens = (username?: string, password?: string, grantType?: GrantTypesEnums) => {
  const uri = `${keycloakUrl}/realms/${keycloakRealm}/protocol/openid-connect/token`;
  const refreshToken = getTokens()?.refreshToken;

  const data =
    grantType === GrantTypesEnums.PASSWORD
      ? qs.stringify({
          grant_type: grantType,
          username: username,
          password: password,
          client_id: keycloakClientId,
        })
      : qs.stringify({
          grant_type: grantType,
          refresh_token: refreshToken,
          client_id: keycloakClientId,
        });

  return axiosAuth.post(uri, data, {
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
  });
};

export const setTokens = async (tokens: { access_token: string; refresh_token: string }) => {
  const { access_token, refresh_token } = tokens;
  try {
    localStorage.setItem('editor_access_token', access_token);
    localStorage.setItem('editor_refresh_token', refresh_token);
  } catch {}
};

export const getTokens = () => {
  try {
    return {
      accessToken: localStorage.getItem('editor_access_token') || '',
      refreshToken: localStorage.getItem('editor_refresh_token') || '',
    };
  } catch {}

  return null;
};

export const removeAcessToken = (): void => {
  localStorage.removeItem('editor_access_token');
};

export const removeTokens = (): void => {
  localStorage.removeItem('editor_access_token');
  localStorage.removeItem('editor_refresh_token');
};




export const clearLocalStorage = () => {
  localStorage.removeItem("asr")
  localStorage.removeItem("tc")
  localStorage.removeItem("pc")
  localStorage.removeItem("itn")
}
export const updateActiveConfiguration = (activeConfiguration: IActiveConfiguration) => localStorage.setItem('asr', JSON.stringify(activeConfiguration))
export const updateActivePunctuator = (activePunctuator: IPunctuator) => localStorage.setItem('pc', JSON.stringify(activePunctuator))
export const updateActiveTrueCaser = (activeTrueCaser: ITrueCaser) => localStorage.setItem('tc', JSON.stringify(activeTrueCaser))
export const updateActiveInverseTextNormalizer = (activeInverseTextNormalizer: IInverseTextNormalizator) => localStorage.setItem('itn', JSON.stringify(activeInverseTextNormalizer))

export const isAuthenticated = (): boolean => {
  const tokens = getTokens();
  if (!tokens) return false;

  if (tokens.accessToken === '' || !tokens.accessToken) return false;

  return true;
};
