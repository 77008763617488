import React from 'react'
import { useState } from 'react'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './tablev3.css'
import '../Home/classess.css'
import { IHistorySession } from './IDashboard';
import { deleteSession, getMergedEditorContent, getSessionTranscripts, mergeSessions } from '../../api/SessionsService';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import NewTable from './NewTable';
import useDownload from '../../hooks/useDownload';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from 'luxon';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setHistoryDate } from '../../redux/features/app/app';
import { SessionState } from '../DashboardHeader/ISearch';
import { paragraphTokens } from '../../shared/paragrapher';
import { convertFromVersion3ToVersion2File } from '../../api/dataConverters';
import { transformNotSavedSessionTranscriptsToLiveWordData } from '../../shared/DataConverters';
import { cloneDeep } from 'lodash';


const V3Table = () => {
    const [data, setData] = useState<IHistorySession[]>([]);
    const startDate = useAppSelector(state => state.app.historyDate);
    const dispatch = useAppDispatch();

    
    const onDateChange = (date: Date | null) => {
        if (!date) return;
        dispatch(setHistoryDate(date))
    }

    const { enqueueSnackbar } = useSnackbar();

    const { handleDownloadDocxFromState } = useDownload();

    const updateData = (newData: IHistorySession[]) => setData(newData);

    const mergeContentAndDownload = async (prefix: string) => {
        
        const mergeSessionIds: number[] = []
        const transcriptSessionIds: {id: number; name: string}[] = [];
        let firstSessionName = "transcript"
        let validID: number | null = null
        const prefixedSessions = data.filter(session => session.name.startsWith(prefix) && !session.isHeader && session.status === SessionState.FINISHED)
        for (let sessionIndex = 0; sessionIndex < prefixedSessions.length; sessionIndex++) {
            const session = prefixedSessions[sessionIndex]
            if (sessionIndex === 0) {
                firstSessionName = session.name
                validID = session.id
            }
            session.versionTags.length > 0 ? mergeSessionIds.push(session.id) : transcriptSessionIds.push({id: session.id, name: session.name});
        }

        /*if (mergeSessionIds.length < 1) {
            enqueueSnackbar("Ena ali več sej ne zadoščajo pogojem za skupni izvoz.", { variant: 'error' })
            return;
        }*/

        const parameters = new URLSearchParams();
        parameters.append("overlap-ms", "5000")

        mergeSessionIds.forEach(id => {
            parameters.append("session-id", id.toString())
        })

        enqueueSnackbar("Združevanje poteka. Seja bo kmalu na voljo.", {variant: 'info'})
        
        setData(data => {
            const copy = cloneDeep(data);
            const mapped =  copy.map(row => {
                row.merging = row.id === validID && row.isHeader
                return row;
            })

            return mapped;
        })

        try {
            const mergedContent = mergeSessionIds.length > 0 ? await getMergedEditorContent(parameters) : undefined;
            const editorStates: EditorState[] = []
            for (let transcriptSessionIndex = 0; transcriptSessionIndex < transcriptSessionIds.length; transcriptSessionIndex++) {
                const { id, name } = transcriptSessionIds[transcriptSessionIndex]
                
                const sessionTrasncripts = await getSessionTranscripts(id)
                const paragraphedTranscript = paragraphTokens(sessionTrasncripts.data, undefined, 0);
                const converted = convertFromVersion3ToVersion2File([paragraphedTranscript.newTranscripts]);
                const { newEditorState } = await transformNotSavedSessionTranscriptsToLiveWordData(converted)
                const raw = convertToRaw(newEditorState.getCurrentContent())
                raw.blocks[0].data = {
                    ...raw.blocks[0].data,
                    blockPrefix: {
                        value: name
                    }
                }

                
                const keys = Object.keys(raw.entityMap)
                const lastKey = keys[keys.length - 1]

                const lastEndTime = raw.entityMap[lastKey].data.endTime

                for (let entityIndex = 0; entityIndex < keys.length; entityIndex++) {
                    const entity = raw.entityMap[entityIndex]
                    if ((entity.data.startTime < 5 && !(transcriptSessionIndex === 0 && !mergedContent)) || lastEndTime - entity.data.startTime < 5) {
                        raw.entityMap[entityIndex].data = {
                            ...raw.entityMap[entityIndex].data,
                            isOverlapping: true,
                        }
                    }
                }
                
                editorStates.push(EditorState.createWithContent(convertFromRaw(raw)))
            }

            const editorStatesAll = [...editorStates]
            if (mergedContent) {
                const notRaw = convertFromRaw(mergedContent.data.rawContentState)
                const editorState = EditorState.createWithContent(notRaw)
                editorStatesAll.unshift(editorState)
            }
            await handleDownloadDocxFromState(editorStatesAll, firstSessionName)
        } catch (error) {
            console.log(error)
            enqueueSnackbar("Pri prenosu je prišlo do napake. Kontaktirajte tehnično podporo", {variant: 'error'})
        }

        setData(data => {
            const copy = cloneDeep(data);
            const mapped =  copy.map(row => {
                row.merging = false
                return row;
            })

            return mapped;
        })
    }

    const mergePrefixContent = async (prefix: string) => {

        const mergeSessionIds: number[] = []
        const prefixedSessions = data.filter(session => session.name.startsWith(prefix) && !session.isHeader && session.status === SessionState.FINISHED)
        let name = ""
        let validID: number | null = null
        for (let sessionIndex = 0; sessionIndex < prefixedSessions.length; sessionIndex++) {
            const session = prefixedSessions[sessionIndex]
            if (sessionIndex === 0) {
                validID = session.id
                name = session.name
                if (session.isLocked) {
                    enqueueSnackbar("Seja se združuje ali ureja.", { variant: 'info' })
                    break;
                }
            }

            const sessionHasLabel = session.labels.filter(label => label.label.code === "Končano" && label.label.isAssigned).length === 1
            if (session.versionTags.length > 0 && sessionHasLabel) {
                mergeSessionIds.push(session.id)
            } else {
                break;
            }
        }

        if (mergeSessionIds.length === 1) {
            enqueueSnackbar("Za združevanje potrebujete vsaj dve veljavni seji.", { variant: 'error' })
            return;            
        }
        if (mergeSessionIds.length < 1) {
            enqueueSnackbar("Ena ali več sej ne zadoščajo pogojem za združevanje.", { variant: 'error' })
            return;
        }

        enqueueSnackbar("Združevanje poteka. Seja bo kmalu na voljo.", {variant: 'info'})
        setData(data => {
            const copy = cloneDeep(data);
            const mapped =  copy.map(row => {
                row.merging = row.id === validID && row.isHeader
                return row;
            })

            return mapped;
        })
        try {
            await mergeSessions(mergeSessionIds, name);

        } catch (error) {
            console.log(error)
            const mappedError = error as any
            if (mappedError.response.status === 423) {
                enqueueSnackbar("Seja se združuje ali ureja.", { variant: 'info' })
            }
            enqueueSnackbar("Pri združevanju je prišlo do napake. Kontaktirajte tehnično podporo", {variant: 'error'})
        }

        setData(data => {
            const copy = cloneDeep(data);
            const mapped =  copy.map(row => {
                row.merging = false
                return row;
            })

            return mapped;
        })
    }

    const mergeDiscardedAndDiscardCurrentlyMerged = async (prefix: string) => {
        const mergedSessions = data.filter(session => session.name.startsWith(prefix) && session.sources.includes("MERGED") && !session.isHeader)
        console.log(mergedSessions)
        console.log(prefix)
        if (mergedSessions.length !== 1) {
            enqueueSnackbar("Pri ponovnem združevanju je prišlo do napake.", { variant: 'error' })
            return;
        }

        const mergedSessionId = mergedSessions[0].id
        const mergedSessionName = mergedSessions[0].name

        const sessionsWithLabelFinished = data.filter(session => session.labels.filter(label => label.label.code === "Končano" && label.label.isAssigned).length > 0).map(session => session.id);

        const sessionsIdsToMerge = data.filter(session => session.name.startsWith(prefix) && !session.sources.includes("MERGED") && sessionsWithLabelFinished.includes(session.id)).map(session => session.id);

        console.log(sessionsIdsToMerge)
        enqueueSnackbar("Združevanje poteka. Seja bo kmalu na voljo.", {variant: 'info'})
        setData(data => {
            const copy = cloneDeep(data);
            const mapped =  copy.map(row => {
                row.merging = row.id === mergedSessionId && row.isHeader
                return row;
            })

            return mapped;
        })
        try {
            await mergeSessions(sessionsIdsToMerge, mergedSessionName);
            await deleteSession(mergedSessionId)
        } catch (error) {
            console.log(error);
            enqueueSnackbar("Pri združevanju je prišlo do napake. Kontaktirajte tehnično podporo", {variant: 'error'})
        }
        
        setData(data => {
            const copy = cloneDeep(data);
            const mapped =  copy.map(row => {
                row.merging = false
                return row;
            })

            return mapped;
        })
    }


    const onChangeRaw = (event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined) => {
        console.log(event)
    }


    const addDay = () => {
        const newDate = DateTime.fromISO(startDate.toISOString()).plus({day: 1}).toISO()
        onDateChange(new Date(newDate as string))
    }

    const subtractDay = () => {
        const newDate = DateTime.fromISO(startDate.toISOString()).minus({day: 1}).toISO()
        onDateChange(new Date(newDate as string))
    }

    return (
        <div>
            <div id="date-picker-container">
                <div style={{cursor: 'pointer'}} onClick={subtractDay}><ArrowLeft /></div>
                <DatePicker selected={startDate} className='date_input' value={`${startDate.getDate()}.${startDate.getMonth() + 1}.${startDate.getFullYear()}`} onChangeRaw={onChangeRaw} onChange={onDateChange} />
                <div style={{cursor: 'pointer'}} onClick={addDay}><ArrowRight /></div>
            </div>
            {/*<Button onClick={joinTaggedSessions}>JOIN SESSIONS</Button>
            <Button onClick={mergeContentAndDownload}>JOIN CONTENT</Button>*/}
            <DndProvider backend={HTML5Backend}>
                <NewTable mergeDiscardedAndDiscardCurrentlyMerged={mergeDiscardedAndDiscardCurrentlyMerged} mergeContentAndDownload={mergeContentAndDownload} mergePrefixContent={mergePrefixContent} selectedDate={startDate} updateData={updateData} data={data} />
            </DndProvider>
        </div>
    )
}

export default V3Table;