import { IConfigurationSend, Task } from "../components/Home/ISettings";
import { useAppSelector } from "../redux/store";
import { UserRoleEnums } from "../redux/store/IStore";
import { useSnackbar } from "notistack";
import { useCallback } from "react";

const usePipeline = () => {
    const {
        enqueueSnackbar
    } = useSnackbar();
    
    const activeConfiguration = useAppSelector(store => store.app.activeConfiguration)
    const activePunctuator = useAppSelector(store => store.app.activePunctuator)
    const activeInverseTextNormalizator = useAppSelector(store => store.app.activeInverseTextNormalizator)
    const activeTrueCaser = useAppSelector(store => store.app.activeTrueCaser)
    const speakerSettingsModal = useAppSelector(store => store.app.speakerSettingsModal)
    const user = useAppSelector(store => store.app.user)
    const isUserFrameworkChanger = user && user.userRoles.includes(UserRoleEnums.FRAMEWORK_CHANGER)

    const configuration = useAppSelector(store => store.app.configuration)

    const buildPipeline = useCallback((): IConfigurationSend | null => {
        if (!activeConfiguration.model || !activeConfiguration.language || !activeConfiguration.domain || !activeConfiguration.model) {
            enqueueSnackbar(`Operacija ni možna, ker ni izbran noben model. Preverite nastavitve.`, {
                variant: 'error',
                autoHideDuration: 4000,
            });
            return null;
        }

        const liveConfig: IConfigurationSend = []

        let asrParameters = {}
        if (activeConfiguration.model.enableUnks && activeConfiguration.model.enableUnks.value) asrParameters["enableUnks"] = true
        if (activeConfiguration.model.enableInterims && activeConfiguration.model.enableInterims.value) asrParameters["enableInterims"] = true
        if (activeConfiguration.model.enableSd && activeConfiguration.model.enableSd.value) {
            asrParameters["enableSd"] = true
            if (speakerSettingsModal.numberOfSpeakers) {
                asrParameters["sdMinSpeakers"] = speakerSettingsModal.numberOfSpeakers
                asrParameters["sdMaxSpeakers"] = speakerSettingsModal.numberOfSpeakers
            } else {
                if (speakerSettingsModal.minNumberOfSpeakers) asrParameters["sdMinSpeakers"] = speakerSettingsModal.minNumberOfSpeakers
                if (speakerSettingsModal.maxNumberOfSpeakers) asrParameters["sdMaxSpeakers"] = speakerSettingsModal.maxNumberOfSpeakers
            }
            
        }


        let activeFramework = activeConfiguration.serviceProvider
        if (!isUserFrameworkChanger || !activeFramework) {
            const frameworks = Object.keys(configuration);
            frameworks.forEach(framework => {
                if (
                    configuration[framework] &&
                    configuration[framework][activeConfiguration.language!] &&
                    configuration[framework][activeConfiguration.language!][activeConfiguration.domain!] &&
                    configuration[framework][activeConfiguration.language!][activeConfiguration.domain!][activeConfiguration.model!.name]
                ) {
                    activeFramework = framework
                }
                
            })
        }

        liveConfig.push({
            task: Task.asr,
            config: {
                tag: `${activeFramework}:${activeConfiguration.language}:${activeConfiguration.domain}:${activeConfiguration.model.name}`,
                parameters: asrParameters,
            }
        })

        if (activePunctuator.toggled && !(activePunctuator.language === null || activePunctuator.domain === null || activePunctuator.model === null)) {
            //TODO: Add service provider to punctuator
            let pcParameters = {}
            if (activePunctuator.model.enableSplitToSentences && activePunctuator.model.enableSplitToSentences.value) {
                pcParameters["enableSplitToSentences"] = true
            }
            liveConfig.push({
                task: Task.pc,
                config: {
                    tag: `${activePunctuator.model.serviceProvider}:${activePunctuator.language}:${activePunctuator.domain}:${activePunctuator.model.name}`,
                    parameters: pcParameters
                }
            })
        }

        if (activeInverseTextNormalizator.toggled && !(activeInverseTextNormalizator.language === null || activeInverseTextNormalizator.domain === null || activeInverseTextNormalizator.model === null)) {
            //TODO: Add service provider to normalizer
            liveConfig.push({
                task: Task.itn,
                config: {
                    tag: `${activeInverseTextNormalizator.model.serviceProvider}:${activeInverseTextNormalizator.language}:${activeInverseTextNormalizator.domain}:${activeInverseTextNormalizator.model.name}`,
                    parameters: {},
                }
            })
        }

        if (activeTrueCaser.toggled && !(activeTrueCaser.language === null || activeTrueCaser.domain === null || activeTrueCaser.model === null)) {
            //TODO: Add service provider to trueCaser
            liveConfig.push({
                task: Task.tc,
                config: {
                    tag: `${activeTrueCaser.model.serviceProvider}:${activeTrueCaser.language}:${activeTrueCaser.domain}:${activeTrueCaser.model.name}`,
                    parameters: {},
                }
            })
        }

        //TODO: Update to not be hardcoded. Good for now.
        liveConfig.push({
            task: Task.ac,
            config: {
                tag: `${"LOCAL_AC"}:${"*"}:${"*"}:${"*"}`,
                parameters: {},
            }
        })

        return liveConfig;
    }, [
        configuration,
        activeConfiguration,
        activePunctuator,
        activeInverseTextNormalizator,
        activeTrueCaser,
        speakerSettingsModal
    ])

    return {
        buildPipeline
    }
}

export default usePipeline;