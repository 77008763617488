import React from 'react';
import { ITimestamp } from './IEditor';

export default function TimestampSection({
    timestamp,
  isFirst,
}: {
  timestamp: ITimestamp;
  isFirst: boolean;
}) {
  const { value } = timestamp;

  const firstSplit = value.split("/")[1];
  const spaceSplit = firstSplit.split(" ") 
  const dateSplit = spaceSplit[0].split("-");
  const year = dateSplit[0]
  const month = dateSplit[1]
  const day = dateSplit[2]

  const timeSplit = spaceSplit[1].split("-");
  const hour = timeSplit[0]
  const minute = timeSplit[1]
  const second = timeSplit[2]

  return (
    <div
      contentEditable={false}
      style={{
        userSelect: 'none',
        width: 'fit-content',
        marginBottom: 10,
        marginTop: isFirst ? 0 : 30,
      }}
    >
      <span
        contentEditable={false}
        className="timestamp_span"
        style={{ borderColor: `#2b8dff` }}
      >
        {day}.{month}.{year} {hour}:{minute}:{second}
      </span>
    </div>
  );
}
